import "aos/dist/aos.css";

import { Helmet } from "react-helmet";
import Page from "../../src/components/Page";
import PropTypes from "prop-types";
import React from "react";
import favicon from "../../src/svg/favicon.ico";

export default function TopLayout(props) {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href={favicon} />
        {/* Calendly link widget begin */}
        <link
          href="https://assets.calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
        <script
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
          async
        />
        {/* Calendly link widget end */}
        <script type="text/javascript">
          {`function initApollo(){var n=Math.random().toString(36).substring(7),o=document.createElement("script");
o.src="https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache="+n,o.async=!0,o.defer=!0,
o.onload=function(){window.trackingFunctions.onLoad({appId:"6631f47d27987c093d3f738f"})},
document.head.appendChild(o)}initApollo();`}
        </script>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Page>{props.children}</Page>
    </>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node
};
