import { Palette } from "./theme.type";

export const light: Palette = {
  mode: "light",
  accent: {
    main: "#FEEA00"
  },
  alternate: {
    main: "#f7faff",
    dark: "#edf1f7"
  },
  primary: {
    main: "#009BE5",
    light: "rgba(0, 155, 229, 0.08)",
    dark: "#0288D1",
    contrastText: "#fff"
  },
  secondary: {
    light: "#ffb74d",
    main: "#f9b934",
    dark: "#FF9800",
    contrastText: "rgba(0, 0, 0, 0.87)"
  },
  text: {
    primary: "#1e2022",
    secondary: "rgba(0, 0, 0, 0.60)",
    caption: "#BBDEFB"
  },
  decoration: {
    50: "#E3F2FD"
  },
  divider: "rgba(0, 0, 0, 0.12)",
  background: {
    paper: "#ffffff",
    default: "#ffffff"
  }
};
