import { Theme, responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import { light } from "./palette";

const getTheme = (mode: string, themeToggler?: () => void): Theme =>
  responsiveFontSizes(
    createTheme({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      palette: light,
      shadows: shadows(mode),
      typography: {
        fontFamily: '"Inter", sans-serif',
        button: {
          textTransform: "none",
          fontWeight: "medium"
        },
        h1: {
          fontSize: "3rem",
          fontWeight: 700
        },
        h2: {
          fontSize: "2.5rem",
          fontWeight: 700
        },
        h3: {
          fontSize: "1.5rem",
          fontWeight: 600,
          lineHeight: 1.35
        }
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300
      },
      // Design flaw: when putting content into containers, padding was not considered,
      // thus all content widths are not pixel perfect. Adjusting the lg breakpoint
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1288,
          xl: 1536
        }
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 400,
              borderRadius: 32,
              paddingTop: 8,
              paddingBottom: 8,
              lineHeight: 1.5,
              fontSize: 16
            },
            containedSecondary: mode === "light" ? { color: "white" } : {},
            containedPrimary: {
              boxShadow: "none",
              backgroundColor: light.accent.main,
              color: light.text.primary,
              fontWeight: 600,
              ":hover": {
                backgroundColor: light.accent.main
              }
            }
          },
          variants: [
            {
              props: { size: "large" },
              style: {
                padding: "16px 32px"
              }
            }
          ]
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5
            }
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 5
            },
            input: {
              borderRadius: 5
            }
          }
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8
            }
          }
        }
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      themeToggler
    })
  );

export default getTheme;
